import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContentPageLayout from "../../components/ContentPageLayout"
import Seo from "../../components/seo"
import { getLegalPages } from "../../api/content"
import ReactMarkdown from "react-markdown"
import {FaArrowLeft} from "@react-icons/all-files/fa/FaArrowLeft"
import NewCardForm from "../../components/forms/payments/newCard"
import MobilePayments from "../pwa/payments/mobile"

const SettingsTermsPage = ({ location }) => {
  const [terms, setTerms] = React.useState()
  const [ispwa, setIspwa] =React.useState(null)
  const [mobile, setMobile] = React.useState(false)


  React.useEffect(() => {
    getLegalPages().then(response => {
      if (response.ok) {
        response.json().then(json => {
          setTerms(json)
        })
      }
    })
    let qs = location.search
    let queries = qs.split("?")

    let authorization = undefined
    let refQs = undefined

    if (queries.length > 1) {
      queries[1].split("&").map(query => {
        if (query.includes("authorization")) {
          authorization = query.split("=")[1]
          setIspwa(authorization)
        }
        
      })
    }
  }, [])
  
  return (
    <ContentPageLayout>
      <Seo title="Terminos y Condiciones" />
            {ispwa ? 
        <div className="flex mt-4 ml-4 cursor-pointer">
          <a onClick={()=>{setMobile(!mobile); setIspwa(!ispwa);}} >
            <FaArrowLeft size={25} color="#002821"></FaArrowLeft>
          </a>
        </div>
      :null}
      {mobile ? <MobilePayments
          location={location}
          optionPaymentForm = "newCard"
          ></MobilePayments>:(
      <h1 className="text-1xl ml-4 mt-4 md:mt-12 md:text-3xl md:mx-10">
        TÉRMINOS Y CONDICIONES DE USO, ACCESO Y PRESTACIÓN DE SERVICIOS
      </h1>
          )}
      <>
        {terms && !mobile ? (
          <ReactMarkdown className="p-8 md:mx-14">
            {terms.terminosYCondiciones}
          </ReactMarkdown>
        ) : null}
      </>
    </ContentPageLayout>
  )
}

export default SettingsTermsPage
